.imageWrapper {
  display: flex;
  justify-content: center;
  padding-top: 94px;
  & img {
    border-radius: 12px;
  }
}

.imageWrapperInner {
  position: relative;
  border: 1px solid rgba(79, 79, 79, 0.5);
  border-radius: 12px;
  & img {
    transform: translateX(30px) translateY(-30px);
  }
}

.labelWrapper {
  position: absolute;
  left: -40px;
  bottom: 68px;

  @media (max-width: 768px) {
    width: 316px;
    left: -120px;
  }
}
